<script setup lang="ts">
// vue
import {
  computed,
  onMounted,
  ref,
  useAttrs,
  watch
} from 'vue'

// types
import type { Nullable } from '@revolutionprep/types'

// utilities
import { capitalizeFirst } from '@revolutionprep/utils'

// components
import SelectEmoji from '../select/SelectEmoji.vue'

/**
 * types
 * ==================================================================
 */
interface InputAppendIcon {
  on: Nullable<string>
  off: Nullable<string>
}

/**
 * props
 * ==================================================================
 */
interface Props {
  appendIcon?: InputAppendIcon
  disabled?: boolean
  emoji?: boolean
  formValidation?: boolean
  lazyMode?: boolean
  payloadProperty?: Nullable<string>
  persist?: boolean
  readonly?: boolean
  rules?: string
  showErrorMessages?: boolean
  type?: string
  value?: string | number
  vid: string
}
const props = withDefaults(defineProps<Props>(), {
  appendIcon: () => ({
    on: 'mdi-check',
    off: null
  }),
  disabled: false,
  emoji: false,
  formValidation: false,
  lazyMode: false,
  payloadProperty: null,
  persist: false,
  readonly: false,
  rules: '',
  showErrorMessages: true,
  type: 'text',
  value: ''
})

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits([
  'blur',
  'input',
  'input-update-value'
])

/**
 * fallthrough attributes
 * ==================================================================
 */
const attrs = useAttrs()

/**
 * state
 * ==================================================================
 */
const innerValue = ref<Nullable<string | number>>(null)
const inputElement = ref<Nullable<HTMLInputElement>>(null)

/**
 * watchers
 * ==================================================================
 */
watch(innerValue, (newValue: Nullable<string | number>) => {
  emit('input', newValue)
})

watch(() => props.value, (newValue: string | number) => {
  innerValue.value = newValue
})

/**
 * computed
 * ==================================================================
 */
const fieldColor = computed(() => {
  return props.formValidation && attrs.valid && attrs.dirty
    ? 'success'
    : ''
})

/**
 * methods
 * ==================================================================
 */
function blur () {
  emit('blur')
}

function insertEmoji (emoji: string) {
  if (inputElement.value) {
    // if text field is in focus, insert emoji at cursor
    // otherwise, insert emoji at end of text field text
    const textFieldText = inputElement.value.value
    const caretPosition =
      inputElement.value.selectionStart || textFieldText.length
    innerValue.value =
      textFieldText.substring(0, caretPosition) +
      emoji +
      textFieldText.substring(caretPosition)
  }
}

function save (newValue: string | number) {
  if (props.persist) {
    emit('input-update-value', {
      [props.payloadProperty || props.vid]: newValue
    })
  }
}

/**
 * lifecycle hooks
 * ==================================================================
 */
onMounted(() => {
  if (attrs.modelValue) {
    innerValue.value = attrs.modelValue as Nullable<string | number>
  }

  const input = document.getElementById(
    `v-text-field-${props.vid}`
  ) as HTMLInputElement
  if (input) {
    inputElement.value = input
  }
})
</script>

<template>
  <div class="text-field-container">
    <v-text-field
      v-bind="$attrs"
      :id="`v-text-field-${vid}`"
      v-model="innerValue"
      variant="outlined"
      hide-details
      :color="fieldColor"
      density="compact"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      @update:model-value="save"
      @blur="blur"
    >
      <template #append-inner="{ isFocused }">
        <v-icon
          v-if="isFocused.value && props.formValidation && $attrs.dirty"
          :color="$attrs.valid ? 'success' : 'danger'"
          :icon="$attrs.valid && isFocused ? props.appendIcon.on : props.appendIcon.off"
        />
      </template>
    </v-text-field>
    <SelectEmoji
      v-if="emoji"
      @select-emoji="insertEmoji"
    />
  </div>
  <div
    v-if="$attrs['error-messages'] && showErrorMessages"
    class="ps-2 mb-n3"
    style="width: 100%;"
  >
    <span class="text-red">
      {{ capitalizeFirst(String($attrs['error-messages'])) }}
    </span>
  </div>
</template>

<style scoped>
.text-field-container {
  display: flex;
}
</style>
